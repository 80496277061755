<template>
  <div class=" container">
    <v-row style="width: 100%">
      <v-col cols="8" offset-lg="2" offset-md="2" class="mt-4">
        <Breadcrumbs
          :items="[
            {
              text: 'All Staff',
              disabled: false,
              href: '/admin/staffs/all'
            },
            {
              text: !!$route.query.id ? 'Edit Staff' : 'Add Staff',
              disabled: true,
              href: !!$route.query.id
                ? '/admin/staffs/add'
                : '/admin/staffs/all'
            }
          ]"
        />
        <v-row>
          <v-col>
            <div class="d-flex flex-column mb-3 ml-1 mt-n4">
              <PageTitle
                :text="!!$route.query.id ? 'Edit Staff Details' : `New Staff`"
                class=" font font-weight-bold font-size-lg mb-4"
              />
            </div>
            <div class="d-flex flex-column mx-2 flex-grow-1">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(addNewStaff)">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-text-field
                      label="Name"
                      outlined
                      v-model="staff.name"
                      hint="Enter the name of the staff "
                      class="font font-weight-medium font-size-md"
                      :error-messages="errors[0]"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    rules="required|numeric|min:10"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Phone Number"
                      outlined
                      v-model="staff.phone"
                      hint="Enter your phone number "
                      class="font font-weight-medium font-size-md"
                      :error-messages="errors[0]"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    rules="required|numeric"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Account Number"
                      outlined
                      v-model="staff.accountNumber"
                      hint="Enter your account number "
                      class="font font-weight-medium font-size-md"
                      :error-messages="errors[0]"
                    />
                  </ValidationProvider>
                  <div
                    v-if="!$route.query.id || $route.query.id === profile.id"
                    class=" d-flex flex-row flex-grow-1"
                  >
                    <ValidationProvider
                      :rules="!$route.query.id ? 'required|numeric' : ''"
                      tag="div"
                      class="d-flex flex-column flex-grow-1 mr-1"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        label="Enter your PIN Code"
                        outlined
                        v-model="staff.pin"
                        type="password"
                        hint="Enter your PIN Code "
                        class="font font-weight-medium font-size-md"
                        :error-messages="errors[0]"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      :rules="!$route.query.id ? 'required|numeric' : ''"
                      tag="div"
                      class="d-flex flex-column flex-grow-1 ml-1"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        label="Confirm your PIN Code"
                        outlined
                        type="password"
                        v-model="staff.confirmPin"
                        hint="Confirm your PIN Code"
                        class="font font-weight-medium font-size-md"
                        :error-messages="errors[0]"
                      />
                    </ValidationProvider>
                  </div>

                  <div class="d-flex flex-row flex-grow-1">
                    <v-spacer />
                    <v-btn
                      :disabled="isLoading"
                      :loading="isLoading"
                      color="primary"
                      type="submit"
                    >
                      <span class="font font-sm font-weight-medium "
                        >Save
                      </span>
                    </v-btn>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required, min } from "vee-validate/dist/rules";
import SnackBarMixins from "../../../../mixins/SnackBarMIxins";
import { loadComponent } from "@/utils/helpers";
import { mapGetters } from "vuex";
extend("required", {
  ...required,
  message: `Field is required`
});
extend("numeric", {
  ...numeric,
  message: `Field only accept only numeric characters`
});
extend("min", {
  ...min,
  message: `Minimum characters must be 10`
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SnackBar: loadComponent(`component/SnackBar`),
    PageTitle: loadComponent(`component/PageTitle`),
    Breadcrumbs: loadComponent(`component/Breadcrumbs`)
  },
  computed: {
    ...mapGetters({
      isLoading: "getIsLoading",
      details: "staffs/getStaffDetails",
      profile: "auth/getUserDetails"
    })
  },
  mixins: [SnackBarMixins],
  data() {
    return {
      staff: {
        name: "",
        phone: "",
        accountNumber: "",
        pin: "",
        confirmPin: "",
        userType: "ADMIN"
      }
    };
  },
  watch: {
    details(payload) {
      const { name, phone, accountNumber } = payload;
      this.staff = { name, phone, accountNumber };
    }
  },
  methods: {
    addNewStaff() {
      if (this.staff.pin !== this.staff.confirmPin) {
        this.$store.dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: `PIN Code doesn't match`,
            status: "red"
          },
          { root: true }
        );
        return;
      }
      if (this.$route.query.id) {
        this.$store.dispatch("staffs/update", {
          _id: this.$route.query.id,
          ...this.staff
        });
        return;
      }
      this.$store.dispatch("staffs/create", {
        ...this.staff
      });
    }
  },
  created() {
    if (this.$route.query.id) {
      this.$store.dispatch("staffs/details", this.$route.query.id);
    }
  }
};
</script>
